// =================================================================================================
// This is basically a radio button. Can select one option from a group.
// =================================================================================================

import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useContext } from "react";
import { getFieldOptions } from "../../formHandling";
import { JourneyContext } from "../../JourneyContext";
import { TFormField, TFieldChangeHandler } from "../../types";

// =================================================================================================
// Main component
// =================================================================================================

export const OptionField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  const { field, value, changeHandler } = props;
  const journeyContext = useContext(JourneyContext);
  // Options can either be an array of strings, or a { label: string, value: any } object
  const localOptions = getFieldOptions(field, journeyContext.formData);

  const localChangeHandler = (newValue: any) => {
    if (!field.required && localOptions !== undefined) {
      for (let o of localOptions) {
        if (o.value === value) {
          changeHandler(field, undefined);
          return;
        }
      }
    }
    changeHandler(field, newValue);
  };

  // -----------------------------------------------------------------------------------------------
  // Render helpers
  // -----------------------------------------------------------------------------------------------

  const renderButtons = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={2} className="optionField">
        {localOptions.map((option, index) => {
          return (
            <Button
              className="optionButton"
              color="primary"
              disabled={field.disabled || option.disabled}
              key={index}
              size="large"
              variant={value === option.value ? "contained" : "outlined"}
              sx={{
                textTransform: "none",
              }}
              onClick={() => localChangeHandler(option.value)}
            >
              {option.label || "(label missing)"}
            </Button>
          );
        })}
      </Stack>
    );
  };

  const renderCheckboxes = () => {
    return (
      <Stack>
        {localOptions.map((option, index) => {
          return (
            <FormControlLabel
              disabled={field.disabled}
              key={index}
              control={
                <Checkbox
                  checked={(value && value.includes(option.value)) ?? false}
                  onChange={(event, checked) => {
                    const newValue = value ? [...value] : [];
                    if (checked) {
                      newValue.push(option.value);
                    } else {
                      const idx = newValue.findIndex((v) => v === option.value);
                      newValue.splice(idx, 1);
                    }
                    if (newValue.length === 0) {
                      changeHandler(field, undefined);
                    } else {
                      changeHandler(field, newValue);
                    }
                  }}
                />
              }
              label={option.label}
            />
          );
        })}
      </Stack>
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return field.variant === "checkbox" ? renderCheckboxes() : renderButtons();
};
