// =================================================================================================
// Main App
// =================================================================================================

import "../App.scss";
import { useContext, useEffect, useState } from "react";
import {
  DeploymentHost,
  TBranding,
  TError,
  TJourneyConfig,
  TJourneyMode,
  TProductData,
} from "../types";
import { Journey } from "../components/Journey";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SuccessPage } from "../components/SuccessPage";
import {
  configureAPIs,
  DEFAULT_UUID,
  getBranding,
  getProduct,
  getProducts,
  getQuote,
  getQuoteSummary,
} from "../apiCalls";
import { JourneyPicker } from "../components/JourneyPicker";
import { defaultBrand, defaultTheme } from "../defaultBranding";
import { CenteredError } from "../components/CenteredError";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { JourneyEditApp } from "../JourneyEditApp";
import { getEnvParams } from "../utils";
import { ProductsContext } from "../ProductsApiContext";
import { allJourneyConfigs, getJourneyById } from "../journeyConfigs";
import { activeJourneyContext } from "../activeJourneyContext";
import { CookieBanner } from "../components/CookieBanner";
import HomeEmergencyLandlord from "../journeyConfigs/pro-df4d7887-f446-4ba6-a0d7-530cfb3fc149/HomeEmergencyLandlord.json";
import { useCookies } from "react-cookie";

console.log("Loaded on " + new Date());

declare global {
  interface Window {
    [key: `ga-disable-${string}`]: boolean;
  }
}

// =================================================================================================
// Main component
// =================================================================================================

const envParams = getEnvParams();

const Home = () => {
  // -----------------------------------------------------------------------------------------------
  // App state
  // -----------------------------------------------------------------------------------------------

  const [journeys, setJourneys] = useState<TProductData[] | null>(null);
  const [activeJourney, setActiveJourney] = useState<TJourneyConfig | null>(
    null
  );
  const [theme, setTheme] = useState(defaultTheme);
  const [branding, setBranding] = useState<TBranding>(defaultBrand);
  const [journeyMode, setJourneyMode] = useState<TJourneyMode>("LOADING");
  const [error, setError] = useState<TError>({});
  const [productData, setProductData] = useState<TProductData | null>(null);
  const [successToken, setSuccessToken] = useState("");

  const { updateProductData } = useContext(ProductsContext);

  const [cookies] = useCookies(["cookieBanner"]);
  console.log(cookies)
  // -----------------------------------------------------------------------------------------------
  // useEffect for loading initial data from APIs
  // -----------------------------------------------------------------------------------------------

  console.log("environment is", JSON.stringify(envParams, null, 2));

  useEffect(() => {
    const fetchFromAPIs = async () => {
      configureAPIs(
        envParams.hostname as DeploymentHost,
        envParams.uuid ?? undefined
      );

      const { uuid, userSessionToken, quoteId, productId } = envParams;

      // -------------------------------------------------------------------------------------------
      // Early bail
      // -------------------------------------------------------------------------------------------
      if (!uuid && !userSessionToken && !quoteId && envParams.mode !== "EDIT") {
        setJourneyMode("ERROR");
        setError({
          title: "No Parameters",
          content:
            `None of the required parameters are specified. Remember to add e.g. ` +
            `?uuid=${DEFAULT_UUID} to the URL.`,
        });

        return;
      }

      // -------------------------------------------------------------------------------------------
      // If userSessionToken exists, we got redirected to from Go Cardless
      // Success page does its own API calls for branding, etc.
      // -------------------------------------------------------------------------------------------
      if (userSessionToken !== null) {
        setJourneyMode("SUCCESS");
        return;
      }

      // -------------------------------------------------------------------------------------------
      // If quoteId exists, we are resuming a quote or doing an MTA
      // -------------------------------------------------------------------------------------------
      if (quoteId !== null) {
        // Get the quote summary (it matches the original new quote POST response)
        const summary = await getQuoteSummary(quoteId);
        if (summary === null) {
          setJourneyMode("ERROR");
          setError({
            title: "Problem Fetching Data",
            content: "The quote summary could not be fetched.",
          });
          return;
        }
        // Get the full quote (it has our captured formValues)
        const quote = await getQuote(quoteId);
        if (quote === null) {
          setJourneyMode("ERROR");
          setError({
            title: "Problem Fetching Data",
            content: "The quote could not be fetched.",
          });
          return;
        }
        configureAPIs(envParams.hostname as DeploymentHost, quote.mainBrokerId);

        const journey = getJourneyById(quote.productId);
        if (journey === null) {
          setJourneyMode("ERROR");
          setError({
            title: "Problem Fetching Data",
            content: "The journey config could not be fetched.",
          });
          return;
        }
        const branding = await getBranding(quote.mainBrokerId);
        if (branding === null) {
          setJourneyMode("ERROR");
          setError({
            title: "Problem Fetching Data",
            content: "The branding could not be fetched.",
          });
          return;
        }
        if (!handleBranding(branding)) {
          setJourneyMode("ERROR");
          setError({
            title: "Unexpected branding response",
            content: `The format of the branding response is invalid or incomplete.`,
          });
          return;
        }

        // update products context
        const product = await getProduct(quote.mainBrokerId, quote.productId);
        if (!product || product.state !== "active" || !product.published) {
          setJourneyMode("ERROR");
          setError({
            title: "Product Unavailable.",
            content: "Sorry. This product is not available.",
          });
          return;
        }
        setProductData(product);
        updateProductData(product);

        // Populate initial values with submitted data from quote and quote summary
        const initialFormValues = quote.quoteRequest.originalData.formValues;
        const initialValues = {
          ...initialFormValues,
          quote: {
            isIWonder: quote?.isIWonder ?? false,
            ...summary,
            useNewMtaQp: quote?.useNewMtaQp,
            paymentProvider: quote?.paymentProvider,
            paymentFrequency: quote?.paymentFrequency,
          },
        };
        setActiveJourney({ ...journey, initialValues });
        if (quote.state === "QUOTE") {
          setJourneyMode("RESUME");
          console.log("Resuming a created quote");
        } else if (quote.state === "BOUND" || quote.state === "REFERRED") {
          setJourneyMode("MTA");
          console.log("Perform MTA on bound quote");
        } else {
          setJourneyMode("ERROR");
          setError({
            title: "Invalid Quote State",
            content: "The quote is not in a state that can be worked with.",
          });
        }
        return;
      }

      // -------------------------------------------------------------------------------------------
      // If uuid exists, we want to fetch a specific branding
      // -------------------------------------------------------------------------------------------
      if (uuid != null) {
        const branding = await getBranding(uuid);
        if (branding === null) {
          setJourneyMode("ERROR");
          setError({
            title: "Problem Fetching Data",
            content: "The branding could not be fetched.",
          });
          return;
        }
        if (!handleBranding(branding)) {
          setJourneyMode("ERROR");
          setError({
            title: "Unexpected branding response",
            content: `The format of the branding response is invalid or incomplete.`,
          });
          return;
        }
      } else {
        setJourneyMode("ERROR");
        setError({
          title: "Missing UUID",
          content: "No broker UUID was specified.",
        });
        return;
      }

      // -------------------------------------------------------------------------------------------
      // If productId exists, try to fetch the journey for it. Otherwise, fetch all journeys
      // -------------------------------------------------------------------------------------------
      if (productId != null) {
        const product = await getProduct(uuid, productId);
        if (!product || product.state !== "active" || !product.published) {
          setJourneyMode("ERROR");
          setError({
            title: "Product Unavailable.",
            content: "Sorry. This product is not available.",
          });
          return;
        }
        setProductData(product);
        updateProductData(product);
        const journey = getJourneyById(productId);
        if (journey !== null) {
          setActiveJourney(journey);

          setJourneyMode("RESUME"); // Prevent going back to shopfront
        }
      } else {
        const journeys = await getProducts(uuid);
        setJourneys(journeys);
        setJourneyMode("DEFAULT");
      }
    };

    switch (envParams.mode) {
      case "EDIT":
        configureAPIs(envParams.hostname as DeploymentHost);
        setJourneyMode("EDIT");
        break;
      case "TEST":
        configureAPIs(envParams.hostname as DeploymentHost);
        setJourneys(allJourneyConfigs as unknown as TProductData[]);
        setActiveJourney(HomeEmergencyLandlord as TJourneyConfig);
        setJourneyMode("TEST");
        break;
      default:
        fetchFromAPIs();
    }
  }, []);

  useEffect(() => {
    document.title = activeJourney?.product?.title || "Welcome";
  }, [activeJourney]);


  // -----------------------------------------------------------------------------------------------
  // Theme and branding
  // -----------------------------------------------------------------------------------------------

  const handleBranding = (branding: TBranding) => {
    try {
      const brandedTheme = createTheme({
        palette: {
          primary: {
            main: branding.main_color ?? "#3B90CF",
          },
        },
      });
      if (branding.name) {
        document.title = branding.name;
      }
      setBranding(branding);
      setTheme(brandedTheme);
    } catch (e) {
      return false;
    }
    return true;
  };

  // -----------------------------------------------------------------------------------------------
  // Main rendering
  // -----------------------------------------------------------------------------------------------

  if (journeyMode === "EDIT") {
    return <JourneyEditApp />;
  }

  if (journeyMode === "LOADING") {
    return <LoadingIndicator />;
  }

  if (journeyMode === "ERROR") {
    return <CenteredError title={error.title} content={error.content} />;
  }

  if (journeyMode === "SUCCESS") {
    return <SuccessPage quoteId={envParams.userSessionToken} />;
  }
  if (successToken) {
    return <SuccessPage quoteId={successToken} />;
  }

  return (
    <activeJourneyContext.Provider value={activeJourney}>
      <ThemeProvider theme={theme}>
        {activeJourney === null ? (
          <JourneyPicker
            branding={branding}
            journeys={journeys || []}
            setActiveJourney={setActiveJourney}
            mode={journeyMode}
          />
        ) : (
          <Journey
            branding={branding}
            config={{
              ...activeJourney,
              product: {
                ...activeJourney.product,
                title: productData?.productName ?? activeJourney.product.title,
              },
            }}
            setSuccessToken={setSuccessToken}
            setActiveJourney={setActiveJourney}
            mode={journeyMode}
          />
        )}
        {!cookies.cookieBanner && <CookieBanner />}
      </ThemeProvider>
    </activeJourneyContext.Provider>
  );
};

export default Home;
