import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { TVehicle } from "./components/FormFields/Vehicle/VehicleField";
import { getReferrals, getSectionedAnswers } from "./formHandling";
import {
  Config,
  DeploymentHost,
  IStringIndex,
  LookupApisLamdaPayload,
  TBranding,
  TJourneyConfig,
  TProductData,
} from "./types";
import { TVehicleDataResponse } from "./types/UKVD/TVehicleDataResponse";
import { populateTemplate, timeout } from "./utils";
import getConfig from "./config";
import { tempBranding } from "./defaultBranding";

export const DEFAULT_UUID = "com-ee806554-40d4-41d4-ac13-8cb803197b10"; // FinEdge
const DEBUG = true;
const DEFAULT_TIMEOUT = 60 * 1000;
const QUOTE_API_KEY = "2ccae9bd-d642-4ace-940e-59d303e32169";
const config: Config = {
  NF_QUOTE_BASE_URL: "",
  PB_V1_API: "",
  QUOTE_BASE_URL: "",
};
let mainBrokerId = "";

// =================================================================================================
// Configure base URLS given a hostname and broker UUID
// =================================================================================================

export const configureAPIs = (
  hostname: DeploymentHost,
  uuid = DEFAULT_UUID
) => {
  mainBrokerId = uuid;
  Object.assign(config, getConfig(hostname));
  console.log(hostname, JSON.stringify(config, null, 2));
};

// =================================================================================================
// Bank Validation
// =================================================================================================

export const validateBank = async (sortCode: string, accountNumber: string) => {
  const response = await lookupApis({
    api: "bank",
    data: {
      sortCode: sortCode,
      bankAccountNumber: accountNumber,
      options: {
        ApplicationName: "SEG PB Journey",
        Formatter: "DefaultFormatter",
        MaxLines: "6",
        FixTownCounty: "true",
        FixBuilding: "false",
        NormalizeCase: "true",
        NormalizeTownCase: "false",
        UseAnyAvailableCounty: "false",
        UnwantedPunctuation: "",
        CountryCode: "GB",
      },
    },
  });

  return response?.data;
};

// =================================================================================================
// POST a quote with no premium at step 1 (Bypasses quotes processor)
// =================================================================================================

export const createQuote = async (
  journeyConfig: TJourneyConfig,
  apiConfig: any,
  values: IStringIndex<any>,
  branding: any
) => {
  const valuesCopy = JSON.parse(JSON.stringify(values));
  const populatedConfig = populateTemplate(apiConfig, values);
  const answers = getSectionedAnswers(journeyConfig, valuesCopy);
  delete valuesCopy.quote;
  delete valuesCopy.MTA;
  populatedConfig.answers = answers;
  populatedConfig.formValues = valuesCopy;
  const proposer = populatedConfig.payload.quote.proposer;
  const payload = {
    proposer: {
      ...proposer,
      fullName: `${proposer.givenName} ${proposer.familyName}`,
    },
    productId: journeyConfig.product.id,
    mainBrokerId: mainBrokerId,
    mainBrokerName: branding?.name,
    productName: journeyConfig.product.title,
    type: populatedConfig.payload.quote.type,
    quoteRequest: { originalData: populatedConfig },
  };

  // flatten acasta cycle array
  if (
    journeyConfig.product.id === "pro-db68ff14-08b8-4403-b00f-df48d1ffd967" &&
    valuesCopy.cycles
  ) {
    const flattened: Record<string, any> = valuesCopy.cycles.reduce(
      (result: any, cycle: any, idx: number) => {
        for (const key in cycle) {
          result[`cycle${idx + 1}_${key}`] = ["Yes", "No"].includes(cycle[key])
            ? cycle[key]?.toLowerCase()
            : cycle[key];
        }
        return result;
      },
      {}
    );

    populatedConfig.payload.formValues = { ...valuesCopy, ...flattened };
  }
  return await doPost(`${config.PB_V1_API}/quote/create`, payload);
};
// =================================================================================================
// PATCH a quote
// =================================================================================================

export const patchQuote = async (
  journeyConfig: TJourneyConfig,
  apiConfig: any,
  values: IStringIndex<any>
) => {
  const valuesCopy = JSON.parse(JSON.stringify(values));
  const populatedConfig = populateTemplate(apiConfig, values);
  const quoteId = valuesCopy.quote.quoteId;
  const goCardlessPolicy =
    values.quote?.paymentFrequency?.toUpperCase() === "YEARLY" &&
    values.quote?.paymentProvider === "GoCardless";
  const usesOldQP = goCardlessPolicy ? false : !values?.quote?.useNewMtaQp;
  console.log({ goCardlessPolicy, usesOldQP });
  delete valuesCopy.quote;
  delete valuesCopy.MTA;
  const referrals = getReferrals(journeyConfig, valuesCopy);
  const answers = getSectionedAnswers(journeyConfig, valuesCopy);
  populatedConfig.payload.answers = answers;
  populatedConfig.payload.formValues = valuesCopy;
  populatedConfig.payload.quote.referrals = referrals;
  populatedConfig.payload.quote.proposer = {
    ...populatedConfig.payload.quote.proposer,
    email: populatedConfig.payload.quote.proposer?.email ?? "N/A",
  };
  populatedConfig.payload.quote.mainBrokerId = mainBrokerId;
  const { action } =
    actionMap[journeyConfig.product.id as any] || actionMap.defaultAction;

  // flatten acasta cycle array
  if (
    journeyConfig.product.id === "pro-db68ff14-08b8-4403-b00f-df48d1ffd967" &&
    valuesCopy.cycles
  ) {
    const flattened: Record<string, any> = valuesCopy.cycles.reduce(
      (result: any, cycle: any, idx: number) => {
        for (const key in cycle) {
          result[`cycle${idx + 1}_${key}`] = ["Yes", "No"].includes(cycle[key])
            ? cycle[key]?.toLowerCase()
            : cycle[key];
        }
        return result;
      },
      {}
    );

    populatedConfig.payload.formValues = { ...valuesCopy, ...flattened };
  }

  return await action(quoteId, populatedConfig.payload, usesOldQP);
};

export const completeQuote = async (quoteId: string) => {
  const url = `${config.QUOTE_BASE_URL}/${quoteId}`;
  const data = await doPatch(url, { paymentComplete: quoteId });
  if (data && Array.isArray(data) && data.length === 1) {
    return data[0];
  }

  return null;
};

// =================================================================================================
// GET a quote
// =================================================================================================

export const getQuote = async (quoteId: string) => {
  const data = await doGet(`${config.QUOTE_BASE_URL}/${quoteId}`, {
    headers: { Authorization: QUOTE_API_KEY },
  });

  return data || null;
};

export const getQuoteSummary = async (quoteId: string) => {
  const data = await doGet(
    `${config.QUOTE_BASE_URL}/${quoteId}` + "/summaries",
    {
      headers: { Authorization: QUOTE_API_KEY },
    }
  );
  if (data && Array.isArray(data) && data.length === 1) {
    return data[0];
  }

  return null;
};

// =================================================================================================
// Fetch broker branding
// =================================================================================================

export const getBranding = async (brokerId: string) => {
  const data = await doGet(`${config.PB_V1_API}/companies/${brokerId}`);
  const brandingData = data?.data || null;
  if (brandingData) {
    const {
      name,
      website,
      main_color,
      company_icon,
      full_logo,
      servicing_phone,
      servicing_email,
    } = brandingData;

    return {
      name,
      website,
      main_color,
      company_icon,
      servicing_phone,
      servicing_email,
      full_logo,
    } as TBranding;
  } else {
    return tempBranding;
  }
};

// =================================================================================================
// New journey fetching
// =================================================================================================

export const getProducts = async (brokerId: string) => {
  const results = await doGet(
    `${config.PB_V1_API}/products/${brokerId}?type=Insurance&state=active`
  );
  const productsData = results.data
    .map((product: { data: TProductData }) => product.data)
    .filter(
      (p: { state: string; published: boolean }) =>
        p.state === "active" && p.published
    ) as TProductData[];

  console.log("Direct Links: ");
  productsData?.forEach((product) =>
    console.log(
      `${product.productName} ==> ${window.origin}/?uuid=${brokerId}&productId=${product?.id}`
    )
  );
  return productsData;
};

// =================================================================================================
// Fetching single product from products API
// =================================================================================================

export const getProduct = async (brokerId: string, productId: string) => {
  const results = await doGet(
    `${config.PB_V1_API}/product/${productId}/${brokerId}`
  );
  return results.data;
};

// =================================================================================================
// Wrappers for API methods
// =================================================================================================

const doGet = async (url: string, config?: any): Promise<any> => {
  return await doMethod("GET", url, undefined, config);
};

const doPost = async (
  url: string,
  payload: any,
  config?: any
): Promise<any> => {
  return await doMethod("POST", url, payload, config);
};

const doPatch = async (
  url: string,
  payload: any,
  config?: any
): Promise<any> => {
  return await doMethod("PATCH", url, payload, config);
};

const doMethod = async (
  method: "GET" | "POST" | "PATCH",
  url: string,
  payload: any,
  config?: any,
  attempts = 1
): Promise<any> => {
  let apiCall: () => Promise<AxiosResponse>;
  switch (method) {
    case "GET":
      apiCall = async () => {
        return await axios.get(url, {
          timeout: DEFAULT_TIMEOUT,
          ...config,
        });
      };
      break;
    case "POST":
      apiCall = async () => {
        return await axios.post(url, payload, {
          timeout: DEFAULT_TIMEOUT,
          ...config,
        });
      };
      break;
    case "PATCH":
      apiCall = async () => {
        return await axios.patch(url, payload, {
          timeout: DEFAULT_TIMEOUT,
          ...config,
        });
      };
      break;
  }
  try {
    console.log("-->", method, url);
    const { data, status, statusText } = await apiCall();
    if (payload) {
      console.log("PAYLOAD =", JSON.stringify(payload, null, 2));
    }
    if (DEBUG) {
      console.log("<--", status, statusText, url);
      console.log("DATA =", JSON.stringify(data, null, 2));
    }
    if (data?.errorMessage) {
      if (
        data.errorMessage.includes("Connection reset by peer") &&
        attempts < 3
      ) {
        await timeout(1000);
        return await doMethod(method, url, payload, config, attempts + 1);
      }
    } else {
      return data;
    }
  } catch (error) {
    if (DEBUG) {
      console.log("ERR", url);
      console.log("ERROR =", JSON.stringify(error));
    }
  }

  return null;
};

// =================================================================================================
// Fetchify address search
// =================================================================================================

export const postcodeSearch = async (postcode: string) => {
  return await lookupApis({
    api: "address",
    data: {
      postcode: postcode,
      response: "data_formatted",
    },
  });
};

// =================================================================================================
// UK Vehicle Data
// =================================================================================================

export const getUKVehicleData = async (
  VRM: string,
  dataPackage: "VehicleData" | "ValuationData" | "SpecAndOptionsData"
) => {
  try {
    const response = await lookupApis({
      api: "ukvd",
      params: { dataPackage, key_VRM: VRM },
    });
    return response.data as TVehicleDataResponse;
  } catch (error) {
    console.log("UKVD lookup failed:", error);
  }
  return null;
};

export const vehicleSearch = async (key_VRM: string) => {
  let vehicle: TVehicle = {};
  try {
    const result = await lookupApis({
      api: "ukvd",
      params: {
        key_VRM,
        dataPackage: "VehicleData",
      },
    });
    switch (result?.data?.Response?.StatusCode) {
      case "Success":
        const responseData = result.data.Response;
        const { DataItems } = responseData;
        const { VehicleRegistration, ClassificationDetails } = DataItems;

        // Gets last sold date, based of Keeper change history. If there is no keeper changes we use DateFirstRegistered.
        let vehicleLastSoldDate;

        if (DataItems?.VehicleHistory?.KeeperChangesList) {
          vehicleLastSoldDate = new Date(
            DataItems.VehicleHistory.KeeperChangesList.sort(
              (
                a: { DateOfTransaction: string },
                b: { DateOfTransaction: string }
              ) => {
                const dateA = new Date(a.DateOfTransaction);
                const dateB = new Date(b.DateOfTransaction);
                return dateB.getTime() - dateA.getTime();
              }
            )[0].DateOfLastKeeperChange
          );
        } else {
          vehicleLastSoldDate = new Date(
            DataItems.VehicleRegistration.DateFirstRegistered
          );
        }

        vehicle = {
          vehicle_make: ClassificationDetails.Dvla.Make ?? "N/A",
          vehicle_model: ClassificationDetails.Dvla.Model,
          vehicle_make_model: VehicleRegistration.MakeModel ?? "N/A",
          vehicle_range: ClassificationDetails.Smmt.Range ?? "N/A",
          vehicle_trim: ClassificationDetails.Smmt.Trim ?? "N/A",
          vehicle_year: VehicleRegistration.YearOfManufacture ?? "N/A",
          vehicle_colour: VehicleRegistration.Colour ?? "N/A",
          num_of_doors: VehicleRegistration.DoorPlanLiteral ?? "N/A",
          engineCC: VehicleRegistration.EngineCapacity ?? "N/A",
          fuel_type: VehicleRegistration.FuelType ?? "N/A",
          transmission:
            VehicleRegistration.TransmissionType ??
            VehicleRegistration.Transmission ??
            "N/A",
          registration_date: moment(
            VehicleRegistration.DateFirstRegistered,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY"),
          registration_number: key_VRM,
          last_sold: moment(vehicleLastSoldDate, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          ),
        };
        console.log(JSON.stringify(vehicle));
        return vehicle;

      case "ItemNotFound":
        return "Could not find the vehicle you are looking for. Please check the registration and try again.";

      case "KeyInvalid":
        return "The vehicle registration is invalid. Please check and try again.";
    }
  } catch (error) {
    console.log(error, "<-- vehicle search");
  }

  return "There was a problem with the search, please wait a moment and try again.";
};

// =================================================================================================
// Get Product Broker Details
// =================================================================================================
export const getProductBroker = async (
  brokerId = DEFAULT_UUID,
  productId: string
) => {
  const url = `${config.PB_V1_API}/broker?productId=${productId}&brokerId=${brokerId}`;
  return await doGet(url);
};

// =================================================================================================
// Get Cedar Postcode Details
// =================================================================================================
export const getCedarPostcode = async (postcode: string) => {
  const url = `${config.PB_V1_API}/cedar/postcode?postcode=${postcode}`;
  return await doGet(url);
};

const actionMap = {
  "pro-87b397be-396b-4f36-9590-873548e94f5e": {
    action: async (quoteId: string, payload: any) => {
      return await doPatch(config.NF_QUOTE_BASE_URL + "/" + quoteId, payload);
    },
  },
  // Duplicated above for AOIP - also NF - For Now (TM)
  "pro-9dc2ce4a-73a8-4f32-a55f-df022f1275f6": {
    action: async (quoteId: string, payload: any) => {
      return await doPatch(config.NF_QUOTE_BASE_URL + "/" + quoteId, payload);
    },
  },
  defaultAction: {
    action: async (quoteId: string, payload: any, useOldQP: boolean) => {
      if (payload.quote.journey === "patch") {
        return (await doPatch(`${config.PB_V1_API}/quote/${quoteId}`, payload))
          .data;
      }
      if (payload.quote.journey === "bound") {
        return (
          await doPatch(`${config.PB_V1_API}/quote/bound/${quoteId}`, payload)
        )?.data;
      }
      if (
        payload.quote.journey === "mta" ||
        payload.quote.journey === "mta_preview"
      ) {
        console.log({ useOldQP });
        if (!useOldQP) {
          return (
            await doPatch(`${config.PB_V1_API}/quote/mta/${quoteId}`, payload)
          )?.data;
        } else {
          return await doPatch(`${config.QUOTE_BASE_URL}/${quoteId}`, payload);
        }
      }
    },
  },
} as any;

// =================================================================================================
// Stripe create payment intent
// =================================================================================================
export const createPaymentIntent = async (quoteId: string, MTA?: any) => {
  return await doPost(`${config.PB_V1_API}/quote/create/payment-intent`, {
    quoteId,
    brokerId: mainBrokerId,
    MTA,
  });
};

// =================================================================================================
// Updates the current quote stage
// =================================================================================================
export const updateQuoteStage = async (
  quoteId: string,
  pipelineStage: string
) => {
  return await doPatch(`${config.PB_V1_API}/quote/update-stage/${quoteId}`, {
    pipelineStage,
  });
};

// =================================================================================================
// Endpoint / proxy for api calls containing sensetive keys
// the target API needs to be specified in the payload along with other request options needed
// =================================================================================================
export const lookupApis = async (payload: LookupApisLamdaPayload) => {
  const response = await axios.post(`${config.PB_V1_API}/wlj-lookups`, payload);
  return response?.data;
};
