// =================================================================================================
// Field for entering numeric values
// =================================================================================================

import { TextField as TextFieldMUI } from "@mui/material";
import { useState } from "react";
import validator from "validator";
import {
  IStringIndex,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TValidationResult,
} from "../../types";
import { useEffect } from "react";
import { isOperation, getNumberValue } from "../../operations";

// =================================================================================================
// Validation
// =================================================================================================

const getMinimumValue = (field: TFormField, formValues: IStringIndex<any>) => {
  return getNumberValue(field, field.params?.min, formValues);
};

const getMaximumValue = (field: TFormField, formValues: IStringIndex<any>) => {
  return getNumberValue(field, field.params?.max, formValues);
};

export const doNumberFieldValidation = (
  field: TFormField,
  value: string,
  formValues: IStringIndex<any>
): TValidationResult => {
  if (!value || value.length === 0) {
    // Required fields cannot be blank
    if (field.required) {
      return { valid: false, errorMsg: "This field is required." };
    } else {
      return { valid: true };
    }
  } else {
    // Is the value valid for the variant?
    if (field.variant === "float") {
      if (!validator.isFloat(value)) {
        return { valid: false, errorMsg: "Please enter a valid decimal number." };
      }
    } else {
      if (!validator.isInt(value)) {
        return { valid: false, errorMsg: "Please enter a valid integer number." };
      }
    }
    // If we got this far, we can work with the number value
    const numberValue = Number(value);
    const min = getMinimumValue(field, formValues);
    const max = getMaximumValue(field, formValues);
    // Enforce range (both minimum and maximum values)
    if (min !== undefined && max !== undefined && (numberValue > max || numberValue < min)) {
      return {
        valid: false,
        errorMsg: `Please enter a number from ${min} to ${max}`,
      };
    }
    // Enforce maximum value
    if (max !== undefined && numberValue > max) {
      return {
        valid: false,
        errorMsg: `Number cannot be greater than ${max}`,
      };
    }
    // Enforce minimum value
    if (min !== undefined && numberValue < min) {
      return {
        valid: false,
        errorMsg: `Number cannot be smaller than ${min}`,
      };
    }

    return { valid: true };
  }
};

// =================================================================================================
// Main component
// =================================================================================================

export const NumberField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------

  const { field, changeHandler, formData } = props;
  const [value, setValue] = useState(props.value);
  const [validationError, setValidationError] = useState<string | undefined>();

  useEffect(() => {
    // If min/max depends on some kind of operation, a change in another field
    // could mean that this field is suddenly valid or invalid
    if (
      value &&
      (typeof field.params?.max === "string" ||
        typeof field.params?.min === "string" ||
        isOperation(field.params?.max) ||
        isOperation(field.params?.min))
    ) {
      const validation = doNumberFieldValidation(field, value, formData.values);
      setValidationError(validation.errorMsg);
      if (!validation.valid && formData.values[field.name] !== undefined) {
        changeHandler(field, undefined);
      }
      if (validation.valid && formData.values[field.name] !== Number(value)) {
        changeHandler(field, Number(value));
      }
    }
  }, [formData.values, field, value, changeHandler]);

  // -----------------------------------------------------------------------------------------------
  // Component change handler
  // -----------------------------------------------------------------------------------------------

  const localChangeHandler = (newValue: string) => {
    setValue(newValue);
    const validation = doNumberFieldValidation(field, newValue, formData.values);
    if (validation.valid) {
      changeHandler(field, Number(newValue));
      if (validationError?.length) {
        setValidationError(undefined);
      }
    } else {
      setValidationError(validation.errorMsg);
      changeHandler(field, undefined);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return (
    <TextFieldMUI
      disabled={field.disabled}
      fullWidth
      label={field.title}
      variant="outlined"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        localChangeHandler(event.target.value);
      }}
      required={field.required}
      error={Boolean(validationError)}
      helperText={validationError || ""}
      value={value ?? ""}
    />
  );
};

export const formatToMoney = (num: any) => {
  if (isNaN(num)) return undefined;
  if (typeof num !== "number" && typeof num !== "string") {
    throw new Error("Input must be a number or string");
  }

  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(parseFloat(num.toString().replace(/,/g, "")));
};
function unformat(field: TFormField, newValue: string) {
  return field.variant === "money" ? parseInt(newValue.replace(/,/g, ""), 10) : newValue;
}
