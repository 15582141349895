import { Stack, TextField as TextFieldMUI } from "@mui/material";
import { TFieldChangeHandler, TFormField, TValidationResult } from "../../types";
import { useContext, useEffect, useRef, useState } from "react";
import { JourneyContext } from "../../JourneyContext";

// =================================================================================================
// Validation
// =================================================================================================

export const doLicenceFieldValidation = (field: TFormField, value: string): TValidationResult => {
  if (!value || value.trim().length === 0) {
    // Required fields cannot be blank
    if (field.required) {
      return { valid: false, errorMsg: "This field is required." };
    } else {
      return { valid: true };
    }
  } else {
    // This string is perfectly fine. Probably.
    return { valid: true };
  }
};

const part1RegExp = new RegExp(/^[A-Z][A-Z9]{4}$/);
const part2RegExp = new RegExp(/^\d[0156]\d([0][1-9]|[12]\d|3[01])\d$/);
const part3RegExp = new RegExp(/^[A-Z][A-Z9]\d[A-Z0-9]{2}\d?\d?$/);

const validatePart1 = (part1: string) => {
  return part1 ? part1RegExp.test(part1) : true;
};

const validatePart2 = (part2: string) => {
  return part2 ? part2RegExp.test(part2) : true;
};

const validatePart3 = (part3: string) => {
  return part3 ? part3RegExp.test(part3) : true;
};

// =================================================================================================
// Generating licence number from personal data
// =================================================================================================

// Add 5 to the first digit of the month
const addFive = (months: string) => {
  return Number(months[0]) + 5 + months[1];
};

// -------------------------------------------------------------------------------------------------
// The first part of the licence number is derived from lastName
// -------------------------------------------------------------------------------------------------
const generatePart1 = (lastName: string) => {
  if (!lastName) {
    return "";
  }

  // -----------------------------------------------------------------------------------------------
  // The first five characters of lastName (padded with 9s if fewer than 5 characters).
  // For last names beginning with "MAC", they are treated as "MC" for all.
  // -----------------------------------------------------------------------------------------------
  return (
    lastName.startsWith("MAC") ? "MC" + lastName.substring(3, 6) : lastName.substring(0, 5)
  ).padEnd(5, "9");
};

// -------------------------------------------------------------------------------------------------
// The second part of the licence number is derived from date of birth and gender
// -------------------------------------------------------------------------------------------------
const generatePart2 = (dateOfBirth = "", gender = "U") => {
  const dateParts = dateOfBirth.split("/");
  if (dateParts.length !== 3 || !["M", "F"].includes(gender)) {
    return "";
  }
  return (
    // The decade digit from the year of birth (e.g. for 1987 it would be 8)
    dateParts[2][2] +
    // The month of birth in two-digit format
    // 7th character is incremented by 5 if the driver is female i.e. 51–62 instead of 01–12
    (gender === "F" ? addFive(dateParts[1]) : dateParts[1]) +
    // The date within the day of birth in two digit format (i.e. 01–31)
    dateParts[0] +
    // The year digit from the year of birth (e.g. for 1987 it would be 7)
    dateParts[2][3]
  );
};

const generateLicenceNumber = (lastName: string, dateOfBirth: string, gender: string) => {
  return [generatePart1(lastName), generatePart2(dateOfBirth, gender), ""];
};

const splitLicenceNumber = (licence: string) => {
  return [licence.substring(0, 5), licence.substring(5, 11), licence.substring(11)];
};

// =================================================================================================
// Main component
// =================================================================================================

export const LicenceField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  // Need access to other form values
  const journeyContext = useContext(JourneyContext);
  // Should probably not be fixed values, but I am in a hurry here

  const dateOfBirth = journeyContext?.formData?.values["dateOfBirth"] ?? "";
  const lastName: string = (journeyContext?.formData?.values["lastName"] ?? "")
    .replaceAll(" ", "")
    .toUpperCase();
  const gender = journeyContext?.formData?.values["genderAtBirth"] ?? "U";

  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------

  const { field, changeHandler, value } = props;
  const [validationError, setValidationError] = useState<string | undefined>();

  const licenceNumberParts =
    value && value.length >= 16 && value.length <= 18
      ? splitLicenceNumber(value)
      : generateLicenceNumber(lastName, dateOfBirth, gender);

  const [part1, setPart1] = useState(licenceNumberParts[0]);
  const [part2, setPart2] = useState(licenceNumberParts[1]);
  const [part3, setPart3] = useState(licenceNumberParts[2]);

  const part1Valid = validatePart1(part1);
  const part2Valid = validatePart2(part2);
  const part3Valid = validatePart3(part3);

  const box1 = useRef<HTMLInputElement>();
  const box2 = useRef<HTMLInputElement>();
  const box3 = useRef<HTMLInputElement>();

  // -----------------------------------------------------------------------------------------------
  // Component change handler
  // -----------------------------------------------------------------------------------------------

  // This needs to be neater but I just don't have time for it now
  // Redundant calculations = bad

  useEffect(() => {
    setPart1(generatePart1(lastName));
  }, [lastName]);

  useEffect(() => {
    setPart2(generatePart2(dateOfBirth, gender));
  }, [dateOfBirth, gender]);

  useEffect(() => {
    if (
      part1 &&
      validatePart1(part1) &&
      part2 &&
      validatePart2(part2) &&
      part3 &&
      validatePart3(part3)
    ) {
      changeHandler(field, part1 + part2 + part3);
    } else {
      changeHandler(field, undefined);
    }
  }, [part1, part2, part3]);

  const part1Changed = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPart1 = event.target.value.toUpperCase();
    setPart1(newPart1);
    if (newPart1 && validatePart1(newPart1)) {
      box2?.current?.focus();
    }
  };

  const part2Changed = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPart2 = event.target.value.toUpperCase();
    setPart2(newPart2);
    if (newPart2 && validatePart2(newPart2)) {
      box3?.current?.focus();
    }
  };

  const part3Changed = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPart3 = event.target.value.toUpperCase();
    setPart3(newPart3);
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  const getInputProps = (value: string, valid: boolean) => {
    return value
      ? { style: { backgroundColor: valid ? "lightgreen" : "rgba(255,0,0,0.25)" } }
      : undefined;
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <TextFieldMUI
          variant="outlined"
          inputProps={{ size: 5, maxLength: 5 }}
          value={part1}
          onChange={part1Changed}
          InputProps={getInputProps(part1, part1Valid)}
          inputRef={box1}
        />
        <TextFieldMUI
          variant="outlined"
          inputProps={{ size: 6, maxLength: 6 }}
          value={part2}
          onChange={part2Changed}
          InputProps={getInputProps(part2, part2Valid)}
          inputRef={box2}
        />
        <TextFieldMUI
          variant="outlined"
          inputProps={{ size: 7, maxLength: 7 }}
          value={part3}
          onChange={part3Changed}
          InputProps={getInputProps(part3, part3Valid)}
          inputRef={box3}
        />
      </Stack>
    </>
  );
};
