import { Container, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TBranding } from "../types";
import goCompare from '../svg/goCompare.svg'
import confusedDotCom from '../svg/confused.svg'
import compareTheMarket from '../svg/comapareTheMarket.svg'

export const Branding = (props: {
  branding: TBranding;
  isIWonder?: boolean;
  onBack?: () => void;
  onForward?: () => void;
}) => {
  const { branding, isIWonder } = props;

  const showText = useMediaQuery("(min-width:500px)");

  const renderBackButton = () => {
    return (
      <Stack direction="row" alignItems="center" sx={{ position: "absolute", left: 1 }}>
        <IconButton color="primary" component="span" onClick={props.onBack}>
          <ArrowBackIcon />
        </IconButton>
        {showText && (
          <span style={{ color: branding.main_color, cursor: "pointer" }} onClick={props.onBack}>
            Back
          </span>
        )}
      </Stack>
    );
  };

  const renderForwardButton = () => {
    return (
      <IconButton
        color="primary"
        component="span"
        sx={{ position: "absolute", right: 1 }}
        onClick={props.onForward}
      >
        <ArrowForwardIcon />
      </IconButton>
    );
  };

  const renderButtons = () => {
    return (
      <>
        {props.onBack && renderBackButton()}
        {props.onForward && renderForwardButton()}
      </>
    );
  };

  const renderLogo = () => {
    if (branding.website) {
      const url =
        branding.website.startsWith("https://") || branding.website.startsWith("http://")
          ? branding.website
          : "https://" + branding.website;
      return branding.full_logo ? (
        <a target="_blank" rel="noreferrer" href={url}>
          <img src={branding.full_logo} className="brandingLogo" alt={branding.name} />
        </a>
      ) : (
        <a target="_blank" rel="noreferrer" href={url}>
          <h2>{branding.name}</h2>
        </a>
      );
    } else {
      return branding.full_logo ? (
        <img src={branding.full_logo} className="brandingLogo" alt={branding.name} />
      ) : (
        <h2>{branding.name}</h2>
      );
    }
  };

  const renderExternalLogos = () => {
    return (
           <div style={{
  display: 'flex',
  flexDirection: "row",
  justifyContent: 'center',
  alignItems: 'center'
  }}>
      <div style={{
    margin: '0 10px 0 0',
    maxWidth: "120px"
  }}>
          <img style={{ width: '100%', height: "auto"}} src={goCompare} alt="go compare logo"/>
      </div>

      <div style={{
    margin: '0 10px 0 0',
    maxWidth: "90px"
  }}>
          <img style={{ width: '100%', height: "auto"}} src={compareTheMarket} alt="compare the market logo"/>
          </div>
      <div style={{backgroundColor: 'black', maxWidth: "80px"}}>
          <img style={{ padding: "4px", width: '100%', height: "auto"}} src={confusedDotCom} alt="confused.com logo"/>
          </div>
  </div>
    )
  }


  return (
    <div
      id="branding"
      style={{
        backgroundColor: "white",
        paddingTop: 16,
        paddingBottom: 16,
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
      }}
    >
      {branding.servicing_email && branding.servicing_phone && (
        <Container
          maxWidth="sm"
          sx={{
            paddingBottom: 2,
            color: "rgba(0,0,0,0.8)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography fontSize={14}>
            <b>Any questions?</b> Call{" "}
            <a href={"tel:" + branding.servicing_phone}>{branding.servicing_phone}</a> or email{" "}
            <a href={"mailto:" + branding.servicing_email}>{branding.servicing_email}</a>
          </Typography>
        </Container>
      )}
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {renderLogo()}
        {renderButtons()}
      </Container>
        {isIWonder && renderExternalLogos()}
        
    </div>
  );
};
